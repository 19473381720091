import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import Loader from './Loader';

// модальные окна
import ErrorModal from './ErrorModal';
import MultitapModal from './modals/MultitapModal';
import EnergyLimitModal from './modals/EnergyLimitModal';
import FullEnergyModal from './modals/FullEnergyModal';
import SuccessModal from './modals/SuccessModal';

function Boosts({ user, token, refreshUserData }) {
  const [multitapLevel, setMultitapLevel] = useState(1);
  const [energyLimitLevel, setEnergyLimitLevel] = useState(1);
  const [multitapCost, setMultitapCost] = useState(0);
  const [energyLimitCost, setEnergyLimitCost] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMultitapModalVisible, setMultitapModalVisible] = useState(false);
  const [isEnergyLimitModalVisible, setEnergyLimitModalVisible] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isRestoreAvailable, setRestoreAvailable] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isRestoreModalVisible, setRestoreModalVisible] = useState(false);

  useEffect(() => {
    if (user && user.user_id && token) {
      fetchBoostData(user.user_id, token);
    } else {
      setIsLoading(false);
    }
  }, [user, token]);

  useEffect(() => {
    // Рассчитываем время до следующего восстановления энергии
    if (user && user.last_energy_update) {
      const lastUpdate = new Date(user.last_energy_update);
      const now = new Date();
      const hoursDiff = Math.abs(now - lastUpdate) / 36e5;
      if (hoursDiff < 6) {
        setRestoreAvailable(false);
        const remainingTime = (6 - hoursDiff) * 3600 * 1000; // Время в миллисекундах
        setTimeLeft(remainingTime);
      }
    }
  }, [user]);

  const fetchBoostData = async (userId, token) => {
    try {
      const [userRes, multitapRes, energyLimitRes] = await Promise.all([
        axios.get(`/api/user/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } }),
        axios.get(`/api/multitap/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } }),
        axios.get(`/api/energy_limit/${userId}`, { headers: { 'Authorization': `Bearer ${token}` } })
      ]);

      const userData = userRes.data;
      const currentMultitapLevel = userData.click_value || 1;
      const currentEnergyLimitLevel = userData.energy_tap || 1;

      const nextMultitapData = multitapRes.data || {};
      const nextEnergyLimitData = energyLimitRes.data || {};

      setMultitapLevel(currentMultitapLevel);
      setEnergyLimitLevel(currentEnergyLimitLevel);
      setMultitapCost(nextMultitapData.cost || 0);
      setEnergyLimitCost(nextEnergyLimitData.cost || 0);
      setIsLoading(false);
    } catch (err) {
      console.error('Ошибка при получении данных бустов:', err);
      setError('Ошибка при загрузке данных бустов.');
      setIsLoading(false);
    }
  };

  const handlePurchase = async (type) => {
    try {
      const response = await axios.post(`/api/purchase_${type}`, { user_id: user.user_id }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data.success) {
        fetchBoostData(user.user_id, token);
        setSuccessMessage(type === 'multitap' ? '+1 монет за тап' : '+500 энергии');
        closeModal();
        // Обновляем данные на главной странице после успешной покупки
        if (refreshUserData) {
          refreshUserData(); // Вызов функции обновления данных пользователя
        }
      } else {
        alert(response.data.message || 'Покупка не удалась');
      }
    } catch (err) {
      console.error('Ошибка при покупке:', err);
      setError('Ошибка при покупке. Пожалуйста, попробуйте позже.');
    }
  };

  const restoreEnergy = () => {
    axios.post('/api/restore_energy', {}, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      if (response.data.success) {
        // Закрываем модальное окно восстановления и открываем окно успешного выполнения
        setRestoreModalVisible(false);
        setTimeout(() => {
          setSuccessMessage('Энергия успешно восстановлена');
          setSuccessModalVisible(true);
        }, 300); // Добавляем небольшую задержку для плавного перехода
        refreshUserData();
        setRestoreAvailable(false);
        setTimeLeft(6 * 3600 * 1000); // Обратный отсчет на 6 часов
      } else {
        alert(response.data.message);
      }
    }).catch(error => {
      console.error('Ошибка восстановления энергии:', error);
    });
  };

  const closeModal = () => {
    setMultitapModalVisible(false);
    setEnergyLimitModalVisible(false);
    setTimeout(() => setSuccessModalVisible(true), 300); // Плавное открытие модального окна успешной покупки
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1000);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timeLeft]);

  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes}:${seconds}`;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <div className="game">
      <h1>Бусты</h1>

      <div className="boost-section">
        <h4>Бесплатные ежедневные усилители</h4>
        <div className="boost-item">
          <div className="boost-name">Full Energy</div>
          <div className="boost-action">
            {isRestoreAvailable ? (
              <button onClick={() => setRestoreModalVisible(true)}>Восстановить энергию</button>
            ) : (
              <button disabled>Восстановить энергию (Доступно через {formatTimeLeft(timeLeft)})</button>
            )}
          </div>
        </div>
      </div>

      <div className="boost-section">
        <h4>Усилители</h4>

        <div className="boost-item">
          <div className="boost-name">Multitap <br/>(Уровень {multitapLevel + 1})</div>
          <div className="boost-action">
            <button onClick={() => setMultitapModalVisible(true)}>
              Купить за {multitapCost} монет
            </button>
          </div>
        </div>

        <div className="boost-item">
          <div className="boost-name">Energy Limit <br/>(Уровень {energyLimitLevel + 1})</div>
          <div className="boost-action">
            <button onClick={() => setEnergyLimitModalVisible(true)}>
              Купить за {energyLimitCost} монет
            </button>
          </div>
        </div>
      </div>

      <BottomNav onNavClick={() => {}} />

      {/* Модальные окна */}
      <MultitapModal
        isVisible={isMultitapModalVisible}
        onClose={() => setMultitapModalVisible(false)}
        onConfirm={() => handlePurchase('multitap')}
        cost={multitapCost}
      />
      <EnergyLimitModal
        isVisible={isEnergyLimitModalVisible}
        onClose={() => setEnergyLimitModalVisible(false)}
        onConfirm={() => handlePurchase('energy_limit')}
        cost={energyLimitCost}
      />
      <FullEnergyModal
        isVisible={isRestoreModalVisible}
        onClose={() => setRestoreModalVisible(false)}
        onConfirm={restoreEnergy}
      />
      <SuccessModal
        isVisible={isSuccessModalVisible}
        message={successMessage}
        onClose={() => setSuccessModalVisible(false)}
      />
    </div>
  );
}

export default Boosts;
