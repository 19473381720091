import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import Loader from './Loader';

// модальные окна
import ErrorModal from './ErrorModal';
import VideoCodeModal from './modals/VideoCodeModal';  // Модальное окно для ввода кода видео
import SuccessModal from './modals/SuccessModal';

function Tasks({ user, token, refreshUserData }) {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);  // Для выбранного задания
  const [videoCodeModalVisible, setVideoCodeModalVisible] = useState(false);  // Видимость модального окна для видео
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [taskStates, setTaskStates] = useState({});  // Хранение состояния каждой задачи

  useEffect(() => {
    fetchTasks();
  }, []);

  // Получение всех активных заданий
  const fetchTasks = async () => {
    try {
      const response = await axios.get('/api/tasks', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setTasks(response.data);
      setIsLoading(false);
    } catch (error) {
      setError('Ошибка при загрузке заданий.');
      setIsLoading(false);
    }
  };

  // Открытие ссылки на видео
  const handleVideoTask = (task) => {
    // Проверяем, был ли уже клик по этой задаче
    if (taskStates[task.id] === 'checking') {
      // Открываем модальное окно для ввода кода
      setVideoCodeModalVisible(true);
      setSelectedTask(task);
    } else {
      // Открываем ссылку в новом окне
      window.open(task.link, '_blank');
      // Меняем состояние задачи на "проверка"
      setTaskStates((prevState) => ({
        ...prevState,
        [task.id]: 'checking',
      }));
    }
  };

  // Отправка кода видео
  const submitVideoCode = (code) => {
    axios.post('/api/verify_video_code', { task_id: selectedTask.id, code, user_id: user.user_id }, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
    .then(response => {
      if (response.data.success) {
        completeTask(selectedTask);  // Отмечаем задание как выполненное
        setVideoCodeModalVisible(false);
      } else {
        alert('Неверный код.');
      }
    })
    .catch(err => {
      setError('Ошибка при вводе кода.');
    });
  };

  // Выполнение обычного задания
  const completeTask = async (task) => {
    try {
      const response = await axios.post('/api/complete_task', {
        task_id: task.id,
        user_id: user.user_id
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data.success) {
        fetchTasks();  // Обновляем задания
        setSuccessMessage(`${task.name} успешно выполнено!`);
        setSuccessModalVisible(true);
        refreshUserData();  // Обновляем данные пользователя
        // Сбрасываем состояние кнопки после выполнения задания
        setTaskStates((prevState) => ({
          ...prevState,
          [task.id]: 'completed',
        }));
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      setError('Ошибка выполнения задания.');
    }
  };

  // Логика клика по заданию
  const handleTaskClick = (task) => {
    if (task.task_type === 'video') {
      handleVideoTask(task);  // Открываем ссылку на видео, затем показываем модальное окно
    } else {
      completeTask(task);  // Выполняем обычное задание
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <div className="game">
      <h3>Заработай больше монет, выполняя задания</h3>
      <div className="tasks-list">
        {tasks.map(task => (
          <div key={task.id} className={`task-item ${!task.is_active ? 'inactive' : ''}`}>
            <div className="task-name">{task.name}</div>
            <div className="task-reward">+ {task.reward} монет</div>
            {task.is_active ? (
              <button onClick={() => handleTaskClick(task)}>
                {taskStates[task.id] === 'checking' ? 'Проверить' : 'Выполнить'}
              </button>
            ) : (
              <span>Задание выполнено</span>
            )}
          </div>
        ))}
      </div>

      <BottomNav />

      {/* Модальные окна */}
      {videoCodeModalVisible && (
        <VideoCodeModal
          task={selectedTask}
          onClose={() => setVideoCodeModalVisible(false)}
          onConfirm={(code) => submitVideoCode(code)}
        />
      )}

      <SuccessModal
        isVisible={successModalVisible}
        message={successMessage}
        onClose={() => setSuccessModalVisible(false)}
      />
    </div>
  );
}

export default Tasks;
