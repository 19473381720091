import React from 'react';

function FullEnergyModal({ isVisible, onClose, onConfirm }) {
    if (!isVisible) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Подтверждение восстановления энергии</h2>
                <p>Вы уверены, что хотите восстановить энергию до максимального уровня?</p>
                <div className="modal-actions">
                    <button onClick={onClose}>Отмена</button>
                    <button onClick={onConfirm}>Подтвердить</button>
                </div>
            </div>
        </div>
    );
}

export default FullEnergyModal;
