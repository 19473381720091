import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import ErrorModal from './ErrorModal';

function Leaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Состояние для управления видимостью контейнера

  useEffect(() => {
    axios.get('/api/leaderboard')
      .then(res => {
        setLeaderboardData(res.data);
        setIsLoading(false);
        setTimeout(() => {
          setIsVisible(true); // Показать контейнер с анимацией
        }, 100); // Небольшая задержка перед анимацией
      })
      .catch(err => {

        setError('Ошибка при загрузке данных рейтинга.');
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <div className="game">
      <h1>Рейтинг</h1>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className={`leaderboard-container ${isVisible ? 'visible' : ''}`}>
          <table className="leaderboard-table">
            <thead>
              <tr>
                <th>Номер</th>
                <th>Имя</th>
                <th>Уровень</th>
                <th>Рефералов</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.map((user, index) => (
                <tr key={user.user_id}>
                  <td>{index + 1}</td>
                  <td>{user.username || 'Аноним'}</td>
                  <td>{user.click_value}</td>
                  <td>{user.count_referal}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <BottomNav onNavClick={() => {}} />
    </div>
  );
}

export default Leaderboard;
