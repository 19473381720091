import React, { useState, useEffect } from 'react';
import axios from 'axios';
import coinImage from '../assets/images/coin.png';
import BottomNav from './BottomNav';
import ErrorModal from './ErrorModal'; // Добавляем ErrorModal для отображения ошибок

const Game = ({ user, token, refreshUserData  }) => {
    const [currentUser, setCurrentUser] = useState(user);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null); // Состояние для ошибки
    const [activeTab, setActiveTab] = useState('home'); // Состояние для активной вкладки

    useEffect(() => {
        // Убедимся, что token и currentUser.user_id доступны
        if (currentUser && currentUser.user_id && token) {
            fetchUserData(token, currentUser.user_id)
                .then(userData => {
                    setCurrentUser(userData);
                    setIsLoading(false); // Завершаем загрузку после получения данных
                })
                .catch(error => {
                    setError(`Failed to fetch user data: ${error.message}`);
                    setIsLoading(false); // Также завершаем загрузку в случае ошибки
                });
        } else {
            setIsLoading(false); // Если token или user_id не доступны, завершаем загрузку
        }
    }, [currentUser.user_id, token]);

    const fetchUserData = async (token, userId) => {
        try {
            const response = await axios.get(`/api/user/${userId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const handleMultiTap = async (e) => {
        e.preventDefault();
        const touchPoints = e.touches.length;
        if (currentUser.energy >= touchPoints * currentUser.click_value) {
            const updatedUser = {
                ...currentUser,
                coins: currentUser.coins + touchPoints * currentUser.click_value,
                energy: currentUser.energy - touchPoints * currentUser.click_value
            };
            try {
                const res = await axios.post('/api/user/update', updatedUser, {
                    headers: { 'Authorization': `Bearer ${token}` } // Используем token из props
                });
                if (res.data.success) {
                    setCurrentUser(updatedUser);
                }
            } catch (err) {
                console.error('Ошибка при обновлении пользователя:', err);
                setError('Failed to update user.');
            }
        }
    };

    const handleNavClick = (page) => {
        setActiveTab(page); // Устанавливаем активную вкладку
        console.log(`Navigate to: ${page}`);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <ErrorModal message={error} onClose={() => setError(null)} />; // Показываем модальное окно при ошибке
    }

    return (
        <div className="game">

            <h4>{currentUser.name_user_user_id}</h4>
            <h2>{currentUser.coins}</h2>
            <h3><span className="energy">{Math.floor(currentUser.energy)}</span>/{currentUser.energy_limit}</h3>
            <img 
                src={coinImage} 
                alt="Tap me!" 
                onTouchStart={handleMultiTap} 
                className={`coin ${currentUser.energy === 0 ? 'disabled' : ''}`}
                style={{ cursor: currentUser.energy === 0 ? 'not-allowed' : 'pointer' }}
                tabIndex="-1"
            />
            <BottomNav onNavClick={handleNavClick} activeTab={activeTab} /> {/* Передаем активную вкладку */}
        </div>
    );
};

export default Game;
