import React from 'react';

function SuccessModal({ isVisible, message, onClose }) {
  if (!isVisible) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Покупка успешна!</h2>
        <p>{message}</p>
        <div className="modal-actions btn_close">
          <button onClick={onClose}>Закрыть</button>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
