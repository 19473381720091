import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BottomNav from './BottomNav';
import ErrorModal from './ErrorModal';
import Loader from './Loader';

function Referrals({ user, token }) {

  const [referrals, setReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user && user.user_id && token) {
      fetchReferrals(user.user_id, token);
    } else {
      setIsLoading(false); // Завершаем загрузку, если user или token не определены
    }
  }, [user, token]);

  const fetchReferrals = (userId, token) => {
    console.log("Запрашиваем рефералы для пользователя: ", userId); // Лог для проверки userId
    axios.get(`/api/referrals/${userId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(res => {
        console.log("Рефералы получены: ", res.data); // Лог для проверки данных
        setReferrals(res.data);
        setIsLoading(false);
      })
      .catch(err => {
        console.error('Ошибка при запросе к /api/referrals:', err);
        setError('Ошибка при загрузке данных рефералов.');
        setIsLoading(false);
      });
  };

  const handleCopyLink = () => {
    if (user && user.user_id) {
      const referralLink = `https://t.me/TestClicksBot?start=${user.user_id}`;
      console.log("Копирование ссылки: ", referralLink);
      navigator.clipboard.writeText(referralLink).then(() => {
        alert('Ссылка скопирована!');
      }).catch(err => {
        console.error('Ошибка при копировании ссылки:', err);
      });
    }
  };

  const handleInviteFriend = () => {
    if (user && user.user_id) {
      const referralLink = `https://t.me/TestClicksBot?start=${user.user_id}`;
      console.log("Пригласить друга со ссылкой: ", referralLink);
      navigator.clipboard.writeText(referralLink).then(() => {
        alert('Ссылка скопирована, выберите диалог и вставьте её!');
        window.Telegram.WebApp.Minimize(); // Сворачиваем приложение, не закрывая его
      }).catch(err => {
        console.error('Ошибка при копировании ссылки:', err);
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorModal message={error} onClose={() => setError(null)} />;
  }

  return (
    <div className="game">
      <h1>Пригласите друзей и получите бонусы!</h1>
      <div className="invite-buttons">
        <button onClick={handleInviteFriend} className="invite-button">Пригласить друга</button>
  
      </div>
      <h2>Список ваших друзей</h2>
      <button onClick={() => fetchReferrals(user.user_id, token)} className="refresh-button"></button>
      <div className="referrals-table-container">
        {referrals.length > 0 ? (
          <table className="referrals-table">
            <thead>
              <tr>
                <th>Имя</th>
                <th>Монеты</th>
                <th>Уровень</th>
              </tr>
            </thead>
            <tbody>
              {referrals.map((referral) => (
                <tr key={referral.user_id}>
                  <td>
                    {referral.username || 'Аноним'}
                    {referral.premium === 1 && ' ★'} {/* Звездочка для премиум-пользователей */}
                  </td>
                  <td>{referral.coins}</td>
                  <td>{referral.click_value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>У вас пока нет рефералов.</p>
        )}
      </div>
      <BottomNav onNavClick={() => {}} />
    </div>
  );
}

export default Referrals;
