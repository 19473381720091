import React from 'react';
import './ErrorModal.css';

const ErrorModal = ({ message, onClose }) => {
    return (
        <div className="error-modal-backdrop">
            <div className="error-modal">
                <h2>Произошла ошибка</h2>
                <p>{message}</p>
                <button onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
};

export default ErrorModal;
