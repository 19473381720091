import React, { useState } from 'react';

function VideoCodeModal({ task, onClose, onConfirm }) {
  const [code, setCode] = useState('');

  const handleConfirm = () => {
    if (code.trim() !== '') {
      onConfirm(code);
    } else {
      alert('Введите код.');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Введите код из видео</h2>
        <p>Для задания: {task.name}</p>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Введите код"
        />
        <div className="modal-buttons">
          <button onClick={handleConfirm}>Подтвердить</button>
          <button onClick={onClose}>Отмена</button>
        </div>
      </div>
    </div>
  );
}

export default VideoCodeModal;
