import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserFriends, faTasks, faBolt, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './BottomNav.css';

const BottomNav = ({ active }) => {
  const navigate = useNavigate();

  const handleNavClick = (route) => {
    navigate(route);
  };

  return (
    <div className="bottom-nav">
      <button onClick={() => handleNavClick('/referrals')} className={active === 'referrals' ? 'active' : ''}>
        <FontAwesomeIcon icon={faUserFriends} />
      </button>
      <button onClick={() => handleNavClick('/tasks')} className={active === 'tasks' ? 'active' : ''}>
        <FontAwesomeIcon icon={faTasks} />
      </button>
      <button onClick={() => handleNavClick('/')} className={active === 'home' ? 'active' : ''}>
        <FontAwesomeIcon icon={faHome} />
      </button>
      <button onClick={() => handleNavClick('/boosts')} className={active === 'boosts' ? 'active' : ''}>
        <FontAwesomeIcon icon={faBolt} />
      </button>
      <button onClick={() => handleNavClick('/leaderboard')} className={active === 'leaderboard' ? 'active' : ''}>
        <FontAwesomeIcon icon={faChartLine} />
      </button>
    </div>
  );
};

export default BottomNav;
