import React from 'react';

function EnergyLimitModal({ isVisible, onClose, onConfirm, cost }) {
  if (!isVisible) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Energy Limit</h2>
        <p>Увеличивает максимальный лимит энергии.</p>
        <p>+500 энергии к Вашему уровню.</p>
        <p>Цена: {cost} монет</p>
        <div className="modal-actions">
          <button onClick={onClose}>Отмена</button>
          <button onClick={onConfirm}>Подтвердить</button>
        </div>
      </div>
    </div>
  );
}

export default EnergyLimitModal;
