import React from 'react';
import splashScreenImage from '../assets/images/splash-screen.png';


const SplashScreen = () => {
    return (
        <div className="splash-screen" style={{ 
            backgroundImage: `url(${splashScreenImage})`,
            backgroundSize: 'cover',
            width: '100%', 
            height: '100vh',
            position: 'fixed', 
            top: 0, 
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div className="loader"></div>
        </div>
    );
};

export default SplashScreen;
